import React from 'react';
import './Portfolio.css';
import Async from 'react-async';
import Popup from '../Popup/Popup';
class Slider extends React.Component {
  slideIndex = this.props.slideIndex;

  componentWillReceiveProps(nextProps) {
    this.slideIndex = nextProps.slideIndex;
  }

  clickView = (url) => {
    this.videoUrl = url;
    this.showPopup = true;
    this.setState({
      showPopup: this.showPopup,
      videoUrl: this.videoUrl
    })
  }

  closePopup = () => {
    this.showPopup = false;
    this.videoUrl = '';
    this.setState({
      showPopup: this.showPopup,
      videoUrl: this.videoUrl
    })
  }

  Slide = (slideData) => {
    return <div className="slide-content">
      <div className="text">
        <div className="body">
          <p className="header">{slideData.data.title}</p>
          <p>{slideData.data.description}</p>
          {slideData.data.list ?
            <div className="list">
              {slideData.data.list.split(',').map(item => {
                item = item.trim();
                return <div key={item}><i className="fa fa-check"></i>{item}</div>
              })}
            </div>
            : ''}
          <br></br>
          <p style={{ textAlign: "center" }}>
            {slideData.data.url.indexOf('youtu') !== -1 ?
              <a className="see" target="_blank" onClick={(e) => {
                e.preventDefault();
                this.clickView(slideData.data.url)
              }
              } rel="noopener noreferrer"
                href={slideData.data.url}>
                {this.props.language === 'ru' ? 'смотреть' : 'view'}
              </a>
              :
              <a className="see" target="_blank" rel="noopener noreferrer" href={slideData.data.url}>
                {this.props.language === 'ru' ? 'смотреть' : 'view'}
              </a>
            }
          </p>
        </div>
      </div>
    </div>
  }

  carousel() {
    if (this.slideIndex > this.props.data.length) {
      this.slideIndex = 1
    }
    if (this.slideIndex < 1) {
      this.slideIndex = this.props.data.length;
    }
    this.props.onClick(this.slideIndex);
    this.setState({
      slideIndex: this.slideIndex
    });
  }

  prevSlide = () => {
    this.slideIndex--;
    this.carousel();
  }

  nextSlide = () => {
    this.slideIndex++;
    this.carousel();
  }

  Slides = () => {
    return (
      this.props.data.map((item, i) => {
        return <li key={item.id} className={i % 2 ? 'w3-animate-top' : 'w3-animate-bottom'} style={
          {
            display: this.slideIndex === (i + 1) ? 'block' : 'none',
            background: 'url(' + item.image + ') 0% 0% / cover no-repeat'
          }}>
          <this.Slide data={item} />
        </li>
      }))
  }

  render() {
    return (
      <div className="slider-container">
        <div className="prev">
          <button onClick={this.prevSlide}><i className="fa fa-arrow-left"></i></button>
        </div>
        <ul>
          <this.Slides />
          <Popup videoUrl={this.videoUrl} showPopup={this.showPopup} closePopup={this.closePopup} />
        </ul>
        <div className="next">
          <button onClick={this.nextSlide}><i className="fa fa-arrow-right"></i></button>
        </div>
      </div>
    )
  }
}

class Portfolio extends React.Component {
  language = window['lang'] || 'en';
  data = null;
  slides = [];
  slideIndex = 1;
  activeCategory = {};
  loadPortfolio = () =>
    fetch(((window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('denisgidin.test') !== -1) ? "//denisgidin.test/portfolio.json" : "//denisgidin.ru/portfolio.json"))
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())


  Categories = (props) => {
    return props.data.categories.map((item) => {
      return <li className={item === this.activeCategory ? 'active' : ''} key={item.name} onClick={() => props.onClick(item)}> <i className={item.icon}></i>{item.name}</li>
    })
  }

  categoryClick = (item, id = 1, changeState = true) => {
    this.activeCategory = item;
    this.slides = item.listWorks;
    this.slideIndex = parseInt(id);

    if (window.location.hash.indexOf('#portfolio') != -1)
      window.location.hash = '#portfolio&section=' + this.activeCategory.name + '&id=' + this.slideIndex;

    if (changeState) {
      this.setState({ slides: this.slides, slideIndex: 1 })
    }

  }

  changeSlide = (slideId) => {
    window.location.hash = '#portfolio&section=' + this.activeCategory.name + '&id=' + slideId;
  }

  render() {
    return (
      <Async promiseFn={this.loadPortfolio} >
        {({ data, err, isLoading, isFulfilled }) => {

          if (isLoading) return "Loading...";
          if (err) return `Something went wrong: ${err.message}`;

          this.data = data[this.language];
          if (!this.slides.length) {

            let parseParams = decodeURIComponent(window.location.hash).split('&');
            let urlParams = [];
            for (const iterator of parseParams) {
              urlParams[iterator.split('=')[0]] = iterator.split('=')[1] || null;
            }

            let category = this.data.categories.filter((item) => {
              return item.name === urlParams.section
            })[0];

            category = category || this.data.categories[0];
            this.categoryClick(category, urlParams.id, false);
          }


          if (this.slides)
            return (
              <div className="Portfolio">
                <div className="categories">
                  <ul>
                    <this.Categories data={this.data} onClick={this.categoryClick} />
                  </ul>
                </div>
                <Slider language={this.language} data={this.slides} onClick={this.changeSlide} slideIndex={this.slideIndex} />
              </div >);
        }
        }
      </Async>
    )
  }
}

export default Portfolio;
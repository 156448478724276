import React from 'react';
import './Popup.css';

class Popup extends React.Component {

  constructor(props) {
    super(props);
  }


  closePopup = () => {
    this.props.closePopup();
  }

  render() {
    return (
      <div className="modal" style={{ 'display': this.props.showPopup ? 'flex' : 'none' }}>
        <div className="modal-content" id="modalContent">
          <button className="close" onClick={this.closePopup}>
            <i className="fa fa-close"></i>
          </button>
          <div className="video-container">
            <iframe width="800" height="550" src={this.props.videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    )
  }
}

export default Popup;